<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <!-- <a-col :span="24">
            <div style="display: flex; align-items: center"></div>
          </a-col> -->

          <a-row>
            <a-col :span="24">
              <el-page-header
                style="
                  width: 100%;
                  height: 50px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 10px;
                "
                @back="backLegalHome"
                content="学法竞赛"
              >
              </el-page-header>
              <div class="content">
                <!-- 专题课程列表数据 -->
                <template>
                  <template v-if="empty">
                    <a-row
                      type="flex"
                      justify="center"
                      style="
                        margin-bottom: 10px;
                        margin-top: 10px;
                        min-height: 400px;
                      "
                    >
                      <el-empty :description="description"></el-empty>
                    </a-row>
                  </template>
                  <template v-else>
                    <a-row
                      :gutter="24"
                      style="margin-top: 20px; min-height: 500px; margin-top: 0"
                      v-loading="loading"
                      element-loading-text="加载中"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0.2)"
                    >
                      <a-col
                        :span="12"
                        :lg="6"
                        v-for="(item, index) in listData"
                        :key="item.id"
                        @click="onItemClick(index)"
                      >
                        <div
                          style="
                            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
                            border-radius: 8px;
                            overflow: hidden;
                            margin-bottom: 24px;
                            cursor: pointer;
                          "
                        >
                          <div style="position: relative">
                            <el-image
                              style="width: 100%; height: 150px"
                              :src="item.multiMediaList[0].cover"
                              fit="cover"
                            ></el-image>
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: #37b6df;
                                padding: 4px 8px;
                                border-radius: 8px 0 8px 0;
                              "
                            >
                              <div
                                style="
                                  font-size: 12px;
                                  color: #ffffff;
                                  font-weight: 600;
                                  margin-left: 3px;
                                "
                              >
                                {{
                                  item.ingStatus === 1
                                    ? "进行中"
                                    : item.ingStatus === 0
                                    ? "未开始"
                                    : "已结束"
                                }}
                              </div>
                            </div>
                          </div>
                          <div style="padding: 8px">
                            <div
                              class="u-line-2"
                              style="
                                font-size: 14px;
                                color: #101010;
                                font-weight: 700;
                                height: 60px;
                                margin-bottom: 8px;
                              "
                            >
                              {{ item.topic }}
                            </div>

                            <div
                              class="mt-8"
                              style="
                                display: flex;
                                align-items: center;
                                margin-bottom: 8px;
                                justify-content: space-between;
                              "
                            >
                              <div
                                style="
                                  display: flex;
                                  flex-direction: row;
                                  align-items: center;
                                "
                              >
                                <div>
                                  <div
                                    style="
                                      font-size: 12px;
                                      color: #868686;
                                      font-weight: 400;
                                      margin-left: 6px;
                                      word-break: keep-all;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    "
                                  >
                                    {{ item.startDate }} 至
                                  </div>
                                  <div
                                    style="
                                      font-size: 12px;
                                      color: #868686;
                                      font-weight: 400;
                                      margin-left: 6px;
                                      word-break: keep-all;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                    "
                                  >
                                    {{ item.endDate }}
                                  </div>
                                </div>
                              </div>
                              <div
                                style="
                                  font-size: 12px;
                                  color: #a8a8a8;
                                  word-break: keep-all;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{ `${item.participatingCount}人已参加` }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </template>
                </template>
              </div>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.getLearnLawList();
  },
  data() {
    return {
      loading: true,
      empty: false,
      listData: [],
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    getLearnLawList() {
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/topicList",
          method: "post",
          data: {
            areaId: "5f574915b191a62dc8701b7f",
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 0 && res.data && res.data.list.length > 0) {
            this.empty = false;
            this.listData = res.data.list;
            console.log("this.listData :>> ", this.listData);
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    // 点击详情
    onItemClick(i) {
      this.$router.push({
        path: "/legalcompetition/learndetails",
        query: {
          topicId: this.listData[i].topicId,
          resultCanSee: this.listData[i].resultCanSee,
        },
      });
    },
    backLegalHome() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-8 {
  margin-top: 8px;
}
::v-deep .el-progress-bar__outer,
::v-deep .el-progress-bar__inner {
  border-radius: 8px;
}
.total-course {
  font-size: 12px;
  color: #101010;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #37b6df;
  margin-left: 2%;
}
.course-message {
  display: flex;
  align-content: center;
}
::v-deep .el-progress-bar__outer {
  background: #3e8ccd;
}
::v-deep .u-line-2 {
  text-align: left;
}
.u-line-2 {
  -webkit-line-clamp: 3;
}
</style>